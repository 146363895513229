import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import { Menu } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import HeaderLogo from '../../assets/spacemx-light.svg'
import MobileHeaderLogo from '../../assets/icon-light.svg'

const styles = theme => ({
  menuItemWrapper: {
    height: 24
  },
  logoImage: {
    height: 32,
    width: 150
  },
  logoImageMobile: {
    height: 36,
    width: 52
  },
  textWrapper: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  topBarText: {
    color: theme.palette.common.white,
    textOverflow: 'ellipsis',
    paddingLeft: theme.spacing.unit,
    fontSize: 16,
    lineHeight: '24px'
  }
})

const HeaderLogoLink = inject('globalStore')(
  observer(
    ({
      classes,
      showMobileLogo = true,
      showLocation = true,
      history: { location },
      globalStore: { mobileSideNavOpen, setMobileSideNavOpen, mobileTopBarText }
    }) => (
      <Fragment>
        <Hidden mdUp>
          {location.pathname.includes('admin') ? (
            <Grid container justify="flex-start" alignItems="center">
              <Grid
                item
                xs={showLocation ? 2 : 12}
                className={classes.menuItemWrapper}
              >
                <Menu
                  onClick={() => setMobileSideNavOpen(!mobileSideNavOpen)}
                />
              </Grid>
              {showLocation && (
                <Grid item xs={10} className={classes.textWrapper}>
                  <Typography variant="body1" className={classes.topBarText}>
                    {mobileTopBarText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <Link to="/">
              <img
                src={showMobileLogo ? MobileHeaderLogo : HeaderLogo}
                className={
                  showMobileLogo ? classes.logoImageMobile : classes.logoImage
                }
                alt="SpaceMX logo"
              />
            </Link>
          )}
        </Hidden>
        <Hidden smDown>
          <Link to="/">
            <img
              src={HeaderLogo}
              className={classes.logoImage}
              alt="SpaceMX logo"
            />
          </Link>
        </Hidden>
      </Fragment>
    )
  )
)

export default withRouter(withStyles(styles)(HeaderLogoLink))
